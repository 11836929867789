import React from "react"

import { Page } from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Page theme={`silver`}>
    <SEO title="404: Not found" />
    <div className="page page__credits">
      <div className="page__header">
        <h1 className={"h__title"}>Pro and&nbsp;co</h1>
        <h2 className={"h__title h__title--secondary"}>404 non trouvé</h2>
      </div>
      <div className="page__credits__main">
        <p>Désolé la page n'est pas disponible</p>
      </div>
    </div>
  </Page>
)

export default NotFoundPage
